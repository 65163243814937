import React, { useState } from 'react'
// react-router-dom
import { Link } from 'react-router-dom'
// assets
import mainLogo from 'assets/logo/main-logo.svg'
import { ReactComponent as MenuOpenIcon } from 'assets/logo/header-menu-open.svg'
import { ReactComponent as MenuCloseIcon } from 'assets/logo/header-menu-close.svg'

// css
import classes from 'components/Header/Header.module.css'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleMenuClick = () => setIsOpen(!isOpen)

  return (
    <header className={classes.wrapper}>
      <div className={classes.container}>
        <button
          onClick={handleMenuClick}
          className={classes.button}
          type="button"
        >
          {isOpen ? <MenuCloseIcon /> : <MenuOpenIcon />}
        </button>
        <Link to="/" className={classes.logo}>
          <img src={mainLogo} alt="Main logo" />
        </Link>
        <nav
          className={`${classes.menu} ${
            isOpen ? classes.active : classes.hide
          }`}
        >
          <Link className={`${classes.menuItem} ${classes.homeLink}`} to="/">
            Home
          </Link>
          <Link className={classes.menuItem} to="/features">
            Features
          </Link>
          <Link className={classes.menuItem} to="/business">
            Business
          </Link>
          <Link className={classes.menuItem} to="/security">
            Security
          </Link>
          {/* <Link className={classes.menuItem} to="/faq">
            F.A.Q.
          </Link> */}
        </nav>
      </div>
    </header>
  )
}

export default Header
