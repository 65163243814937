import React from 'react'
// prop-types
import T from 'prop-types'
// css
import classes from 'components/Input/Input.module.css'

const Input = ({ onChange, className, placeholder, name, value }) => {
  return (
    <input
      onChange={onChange}
      className={`${classes.input} ${className} `}
      placeholder={placeholder}
      name={name}
      value={value}
      type="text"
    />
  )
}

Input.propTypes = {
  onChange: T.func,
  className: T.string,
  placeholder: T.string,
  name: T.string,
  value: T.string
}

export default Input
