import React from 'react'
// prop-types
import T from 'prop-types'

const DownloadButton = ({ imgSrc, href, alt = '' }) => {
  return (
    <a href={href}>
      <img alt={alt} src={imgSrc} />
    </a>
  )
}

DownloadButton.propTypes = {
  imgSrc: T.string,
  href: T.string,
  alt: T.string
}

export default DownloadButton
