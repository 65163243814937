import React from 'react'
// components
import Breadcrumbs from 'components/Breadcrumbs'
// css
import classes from 'pages/Features/Features.module.css'

const Features = () => {
  return (
    <main className="page">
      <div className={classes.container}>
        <div className={classes.breadcrumbsContainer}>
          <Breadcrumbs data={[{ title: 'Features', href: '/features' }]} />
        </div>
        <div className={classes.features1}>
          <div className={classes.info}>
            <h2 className={classes.title}>Event list & Calendar view</h2>

            <p className={classes.paragraph}>
              All the relevant information is shown on a nice credit card sized
              view. You can instantly see who accepted or declined the event,
              who is the organiser, what time is it starting and where should I
              go.
            </p>
          </div>

          <div className={classes.img} />
        </div>

        <div className={classes.features2}>
          <div className={classes.info}>
            <h2 className={classes.title}>Invite users</h2>

            <p className={classes.paragraph}>
              It is so easy to invite users to your events. The only thing you
              need is that you have their mobile phone number stored in your
              phone’s contact list. No need for e-mail addresses. SoPlan uses
              mobile phone numbers.
            </p>
          </div>

          <div className={classes.img} />
        </div>

        <div className={classes.features3}>
          <div className={classes.info}>
            <h2 className={classes.title}>Search</h2>

            <p className={classes.paragraph}>
              Can’t remember when you have that appointment or birthday party?
              Just search for location, title, or other invited members and we
              will show you all events matching your search criteria.
            </p>
          </div>

          <div className={classes.img} />
        </div>

        <div className={classes.features4}>
          <div className={classes.info}>
            <h2 className={classes.title}>Notifications</h2>

            <p className={classes.paragraph}>
              Yes, we will notify you and your invited members about almost
              everything. As an organizer of an event you can set how long
              before the actual event you want to receive a reminder, is it 15
              minutes or 1 week before. All members receive the same reminder
              notifications.
            </p>
          </div>

          <div className={classes.img} />
        </div>

        <div className={classes.features5}>
          <div className={classes.info}>
            <h2 className={classes.title}>Easy Accept or Decline</h2>

            <p className={classes.paragraph}>
              You can easily accept or decline an event invitation on your home
              screen without having to open the event. This way you can get back
              to your activity quickly.
            </p>
          </div>

          <div className={classes.img} />
        </div>
      </div>
    </main>
  )
}

export default Features
