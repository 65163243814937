import React, { useRef, useState } from 'react'
// axios
import axios from 'axios'
// react-toastify
import { toast, ToastContainer } from 'react-toastify'
// react-google-recaptcha
import ReCAPTCHA from 'react-google-recaptcha'
// constants
import { CONTACT_POST_API_URL } from 'constants/api'
// helpers
import { validate } from 'helpers/validation'
// components
import Breadcrumbs from 'components/Breadcrumbs'
import SubmitButton from 'components/SubmitButton'
import Alert from 'components/Alert'
// css
import classes from 'pages/Contact/Contact.module.css'

const Contact = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  })
  const [token, setToken] = useState('')

  const captchaRef = useRef(null)

  const handleReCaptcha = (value) => setToken(value)

  const handleClick = () => {
    if (!validate(form?.email) || !token) {
      toast.dark(
        () => (
          <Alert>
            <p className={classes.bold}>
              Enter a valid E-mail address or/and confirm that you are not a
              robot. For example:
            </p>
            <p>someone@example.com</p>
          </Alert>
        ),
        {
          toastId: 'validation'
        }
      )
    } else {
      axios
        .post(CONTACT_POST_API_URL, { ...form, token })
        .then(() => {
          setForm({
            name: '',
            email: '',
            message: ''
          })
          captchaRef.current.reset()

          toast.dark(
            () => (
              <Alert>
                <p className={classes.bold}>Success</p>
              </Alert>
            ),
            {
              toastId: 'success'
            }
          )
        })
        .catch(() => {
          toast.dark(
            () => (
              <Alert>
                <p className={classes.bold}>Something went wrong</p>
              </Alert>
            ),
            {
              toastId: 'error'
            }
          )
        })
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setForm((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  return (
    <main className="page">
      <div className={classes.container}>
        <Breadcrumbs data={[{ title: 'Contact', href: '/contact' }]} />
        <h2 className={classes.title}>
          Suggestions, bug reporting or compliments
        </h2>
        <p className={classes.text}>
          If you have suggestions, want to report a bug or just want to give us
          a compliment, please use the below form to do so.
          <br /> If reporting a bug, we need to know the exact steps in order to
          reproduce the bug. The better you describe the steps, the faster we
          can resolve your reported issue.
        </p>

        <form className={classes.form}>
          <div className={classes.inputsContainer}>
            <input
              value={form.name}
              onChange={handleChange}
              placeholder="Enter your Name"
              className={classes.input}
              name="name"
              type="text"
            />
            <input
              value={form.email}
              onChange={handleChange}
              placeholder="Enter your E-mail"
              className={classes.input}
              name="email"
              type="text"
            />
          </div>
          <textarea
            value={form.message}
            onChange={handleChange}
            name="message"
            placeholder="Text message"
            className={classes.textarea}
          />
          <div className={classes.buttonsContainer}>
            <ReCAPTCHA
              ref={captchaRef}
              onChange={handleReCaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <SubmitButton onClick={handleClick}>Send</SubmitButton>
          </div>
        </form>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </main>
  )
}

export default Contact
