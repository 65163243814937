import React from 'react'
// prop-types
import T from 'prop-types'
// css
import classes from 'components/Card/Card.module.css'

const Card = ({ icon, title, text }) => {
  return (
    <div className={classes.card}>
      <div
        className={classes.icon}
        style={{ backgroundImage: `url(${icon})` }}
      />

      <h3 className={classes.title}>{title}</h3>

      <p className={classes.paragraph}>{text}</p>
    </div>
  )
}

Card.propTypes = {
  icon: T.string,
  title: T.string,
  text: T.string
}

export default Card
