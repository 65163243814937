import React from 'react'
// prop-types
import T from 'prop-types'
// css
import classes from 'components/SubmitButton/SubmitButton.module.css'

const SubmitButton = ({ children, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`${classes.button} ${className}`}
      type="button"
    >
      {children}
    </button>
  )
}

SubmitButton.propTypes = {
  children: T.string,
  onClick: T.func,
  className: T.string
}

export default SubmitButton
