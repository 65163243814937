export const whatWeExpectFromYou1 = [
  'When you are investigating one of our systems, bear in mind the proportionality of the attack. There is no need to demonstrate that when you subject our website to the largest DDos-attack in the history of the internet, the site may become unreachable.',

  'This principle of proportionality is also relevant when demonstrating the vulnerability itself. You should not inspect or modify more data then strictly necessary in order to confirm the validity of your finding. For instance, if you are able to modify our homepage, just add a single non-controversial word to it instead of taking over the entire page. If you can obtain access to a database, it suffices to show us a list of the tables that are in there, or perhaps the first record in one of these tables.',

  'You will not share your knowledge of the vulnerability with other parties as long as we have not addressed the issue and we are still within a reasonable timeframe since you reported the issue.',

  'You will delete all confidential information you have obtained during your investigation as soon as we have resolved the vulnerability.'
]

export const whatWeExpectFromYou2 = [
  ' We will respond to your report within seven days in a detailed manner. We will include an estimate of the time we will require to address the issue. Of course, we will regularly keep you posted on our progress.',

  'We will resolve the vulnerability as soon as possible. Here too, proportionality is important: the amount of time required to fix a vulnerability depends on several factors, among which the severity and the complexity of the issue at hand.',

  'When you follow the guidelines that are laid out here, we will not take legal action against you regarding your report.',

  'It is important to us to credit you for what you did - if you wish. We will mention your name in a publication regarding the vulnerability only if you agree to this.',

  'When the issue is resolved, you are allowed to make this public without disclosing real production data.',

  'As a thank you for helping us in better protecting our systems, we would like to reward every report of a vulnerability that was unknown to us at the time. The reward will depend on the severity of the vulnerability and the quality of the report.',

  'Should you find a vulnerability in third party software that we use and that vulnerability is covered by a bug bounty program, we will not try to claim this bounty; you should.'
]
