import React from 'react'
// components
import Breadcrumbs from 'components/Breadcrumbs'
// css
import classes from 'pages/Security/Security.module.css'
import cardsList from 'constants/cardsList'
import Card from 'components/Card'
import { Link } from 'react-router-dom'

const Security = () => {
  return (
    <main className="page">
      <div className={classes.container}>
        <Breadcrumbs data={[{ title: 'Security', href: '/security' }]} />

        <div className={classes.info}>
          <h2 className={classes.title}>Privacy and Security is in our DNA</h2>
          <p className={classes.paragraph}>
            Our founder has a background in cyber security and ensures all
            security aspects are addressed. Whether it is related to development
            of the SoPlan app or daily operations. From day one, we built SoPlan
            with strong security and privacy requirements. We follow the
            secure-by-design principle and continously check and recheck our
            security controls to determine if they are still effective. In
            addition we run a{' '}
            <Link className={classes.highlight} to="/vulnerability-disclosure">
              Vulnerability Disclosure
            </Link>{' '}
            program that allows security researcher to report security issues
            that we have missed.
          </p>

          <h2 className={classes.title}>Encryption</h2>
          <p className={classes.paragraph}>
            At SoPlan, we ensure that we use industry accepted secure encryption
            algorithms. Your and our data is stored with encryption-at-rest
            using AES-256 bit encryption. Network communication between your
            device and our servers is done through TLS 1.2 or higher with strong
            ciphers. This means we will not be able to support older devices and
            browsers.
          </p>

          <h2 className={classes.title}>Least Privilege</h2>
          <p className={classes.paragraph}>
            At SoPlan an individual or application is only allowed to have the
            minimum set of permissions required to perform its task. For
            example, we don’t allow developers access privacy sensitive data.
          </p>

          <h2 className={classes.title}>Updates</h2>
          <p className={classes.paragraph}>
            We know that if you don’t keep your software and libraries up to
            date, than it’s just a matter of time before someone does something
            bad with your systems. We keep our systems up to date and if we
            break something in the process, we will not revert the update as
            most organisations do, but instead fix the issue.
          </p>
        </div>

        <div className={classes.cardsList}>
          {cardsList.map((item, i) => (
            <Card key={i} {...item} />
          ))}
        </div>
      </div>
    </main>
  )
}

export default Security
