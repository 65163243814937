import React from 'react'
// css
import classes from 'pages/PrivacyPolicy/PrivacyPolicy.module.css'
// constants
import {
  automaticallyCollectedInformation,
  howWeUserInformation,
  informationYouAndWeShare,
  ourLegalBasesForProcessingInformation,
  informationYouProvide,
  thirdPartyInformation,
  managingAndDeletingYourInformation
} from 'constants/privacyPolicy'
// components
import SubList from 'components/SubList'
import Breadcrumbs from 'components/Breadcrumbs'

const PrivacyPolicy = () => {
  return (
    <main className="page">
      <div className={classes.container}>
        <Breadcrumbs
          data={[{ title: 'Privacy policy', href: '/privacy-policy' }]}
        />

        <nav>
          <ol className={classes.navList}>
            <li>
              <a href="#introduction">Introduction</a>
            </li>
            <li>
              <a href="#information-we-collect">Information We Collect</a>
            </li>
            <li>
              <a href="#how-we-use-information">How We Use Information</a>
            </li>
            <li>
              <a href="#information-you-and-we-share">
                Information You And We Share
              </a>
            </li>
            <li>
              <a href="#assignment-change-of-control-transfer">
                Assignment, Change Of Control, And Transfer
              </a>
            </li>
            <li>
              <a href="#our-legal-bases-for-processing-information">
                Our Legal Bases For Processing Information
              </a>
            </li>
            <li>
              <a href="#how-you-exercise-your-rights">
                How You Exercise Your Rights
              </a>
            </li>
            <li>
              <a href="#managing-and-deleting-your-information">
                Managing And Deleting Your Information
              </a>
            </li>
            <li>
              <a href="#law-and-protection">Law And Protection</a>
            </li>
            <li>
              <a href="#updates-to-our-policy">Updates To Our Policy</a>
            </li>
          </ol>
        </nav>

        <ul className={classes.infoList}>
          <li id="introduction">
            <h3 className={classes.itemTitle}>1. Introduction</h3>
            <p className={classes.itemParagraph}>
              This Privacy Policy applies to all of our Services unless
              specified otherwise.
            </p>
          </li>

          <li id="information-we-collect">
            <h3 className={classes.itemTitle}>2. Information We Collect</h3>

            <p className={classes.itemParagraph}>
              SoPlan must receive or collect some information to operate,
              provide, improve, understand, customize, support, and market our
              Services, including when you install, access, or use our Services.
              The types of information we receive and collect depend on how you
              use our Services.
            </p>

            <SubList data={informationYouProvide} />

            <SubList data={automaticallyCollectedInformation} />

            <SubList data={thirdPartyInformation} />
          </li>

          <li id="how-we-use-information">
            <h3 className={classes.itemTitle}>3. How We Use Information</h3>
            <p className={classes.itemParagraph}>
              We use the information we have (subject to choices you make) to
              operate, provide, improve, understand, customize, support, and
              market our Services. Here's how:
            </p>

            <SubList data={howWeUserInformation} />
          </li>

          <li id="information-you-and-we-share">
            <h3 className={classes.itemTitle}>
              4. Information You And We Share
            </h3>
            <p className={classes.itemParagraph}>
              You share your information as you use and communicate through our
              Services, and we share your information to help us operate,
              provide, improve, understand, customize, support, and market our
              Services.
            </p>

            <SubList data={informationYouAndWeShare} />
          </li>

          <li id="assignment-change-of-control-transfer">
            <h3 className={classes.itemTitle}>
              5. Assignment, Change Of Control, And Transfer
            </h3>
            <p className={classes.itemParagraph}>
              All of our rights and obligations under our Privacy Policy are
              freely assignable by us to any of our affiliates, in connection
              with a merger, acquisition, restructuring, or sale of assets, or
              by operation of law or otherwise, and we may transfer your
              information to any of our affiliates, successor entities, or new
              owner.
            </p>
          </li>

          <li id="our-legal-bases-for-processing-information">
            <h3 className={classes.itemTitle}>
              6. Our Legal Bases For Processing Information
            </h3>
            <p className={classes.itemParagraph}>
              We collect, use, and share the information we have as described
              above:
            </p>

            <SubList data={ourLegalBasesForProcessingInformation} />
          </li>

          <li id="how-you-exercise-your-rights">
            <h3 className={classes.itemTitle}>
              7. How You Exercise Your Rights
            </h3>
            <p className={classes.itemParagraph}>
              Under the General Data Protection Regulation or other applicable
              local laws, you have the right to access, rectify, port, and erase
              your information, as well as the right to restrict and object to
              certain processing of your information. This includes the right to
              object to our processing of your information for direct marketing
              and the right to object to our processing of your information
              where we are performing a task in the public interest or pursuing
              our legitimate interests or those of a third party. You can access
              or port your information using our in-app Request Account Info
              feature. You can access tools to rectify, update, and erase your
              information. You can object to processing your information by
              deleting your account and no longer user our Services.
            </p>
          </li>

          <li id="managing-and-deleting-your-information">
            <h3 className={classes.itemTitle}>
              8. Managing And Deleting Your Information
            </h3>
            <p className={classes.itemParagraph}>
              We store information until it is no longer necessary to provide
              our services, or until your account is deleted, whichever comes
              first. This is a case-by-case determination that depends on things
              like the nature of the information, why it is collected and
              processed, and relevant legal or operational retention needs.
            </p>
            <p className={classes.itemParagraph}>
              If you would like to manage, change, limit, or delete your
              information, we allow you to do that through the following tools:
            </p>

            <SubList data={managingAndDeletingYourInformation} />
          </li>

          <li id="law-and-protection">
            <h3 className={classes.itemTitle}>9. Law And Protection</h3>
            <p className={classes.itemParagraph}>
              We collect, use, preserve, and share your information if we have a
              good-faith belief that it is reasonably necessary to: (a) respond
              pursuant to applicable law or regulations, to legal process, or to
              government requests; (b) enforce our Terms and any other
              applicable terms and policies, including for investigations of
              potential violations; (c) detect, investigate, prevent, and
              address fraud and other illegal activity, security, or technical
              issues; or (d) protect the rights, property, and safety of our
              users, SoPlan, or others, including to prevent death or imminent
              bodily harm.
            </p>
          </li>

          <li id="updates-to-our-policy">
            <h3 className={classes.itemTitle}>10. Updates To Our Policy</h3>
            <p className={classes.itemParagraph}>
              We will notify you before we make changes to this Privacy Policy
              and give you the opportunity to review the revised Privacy Policy
              before you choose to continue using our Services.
            </p>
          </li>
        </ul>
      </div>
    </main>
  )
}

export default PrivacyPolicy
