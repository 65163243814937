import React from 'react'
// constants
import {
  whatWeExpectFromYou1,
  whatWeExpectFromYou2
} from 'constants/vulnerabilityLists'
// components
import Breadcrumbs from 'components/Breadcrumbs'
// css
import classes from 'pages/VulnerabilityDisclosure/VulnerabilityDisclosure.module.css'

const VulnerabilityDisclosure = () => {
  return (
    <main className="page">
      <div className={classes.container}>
        <Breadcrumbs
          data={[
            {
              title: 'Vulnerability Disclosure',
              href: '/vulnerability-disclosure'
            }
          ]}
        />

        <h3 className={classes.title}>Oops!</h3>

        <p className={classes.paragraph}>
          We at SoPlan are also humans. We might have overlooked something or
          there is a serious zero-day vulnerability which slipped our radar.
        </p>

        <p className={classes.paragraph}>
          That’s why we believe a Vulnerability Disclosure program helps SoPlan
          in becoming more secure. Therefor we have layed out some ground rules
          before you start hacking your way around in our environment.
        </p>

        <h4 className={classes.subTitle}>What we expect from you</h4>

        <ul className={classes.list}>
          {whatWeExpectFromYou1.map((text, i) => (
            <li key={i}>{text}</li>
          ))}
        </ul>

        <h4 className={classes.subTitle}>What we expect from you</h4>

        <ul className={classes.list}>
          <li>
            E-mail your findings as quickly as possible to{' '}
            <span className={classes.highlight}>security@soplan.com</span>
          </li>
          {whatWeExpectFromYou2.map((text, i) => (
            <li key={i}>{text}</li>
          ))}
        </ul>
      </div>
    </main>
  )
}

export default VulnerabilityDisclosure
