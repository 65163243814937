import React from 'react'
// css
import classes from 'components/Alert/Alert.module.css'

// eslint-disable-next-line react/prop-types
const Alert = ({ children }) => {
  return <div className={classes.alert}>{children}</div>
}

export default Alert
