import React, { useState, useRef } from 'react'
// axios
import axios from 'axios'
// toastify
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/Toastify.css'
// react-google-recaptch
import ReCAPTCHA from 'react-google-recaptcha'
// constants
import { SUBSCRIBE_POST_API_URL } from 'constants/api'
// helpers
import { validate } from 'helpers/validation'
// components
import Breadcrumbs from 'components/Breadcrumbs'
import SubmitButton from 'components/SubmitButton'
import Alert from 'components/Alert'
import Input from 'components/Input'
// css
import classes from 'pages/Business/Business.module.css'

const Business = () => {
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const captchaRef = useRef(null)

  const handleReCaptcha = (value) => setToken(value)

  const handleClick = () => {
    if (!validate(email) || !token) {
      toast.dark(
        () => (
          <Alert>
            <p className={classes.bold}>
              Enter a valid E-mail address or/and confirm that you are not a
              robot. For example:
            </p>
            <p>someone@example.com</p>
          </Alert>
        ),
        {
          toastId: 'validation'
        }
      )
    } else {
      axios
        .post(SUBSCRIBE_POST_API_URL, { email, token })
        .then(() => {
          setEmail('')
          captchaRef.current.reset()

          toast.dark(
            () => (
              <Alert>
                <p className={classes.bold}>Success</p>
              </Alert>
            ),
            {
              toastId: 'success'
            }
          )
        })
        .catch(() => {
          toast.dark(
            () => (
              <Alert>
                <p className={classes.bold}>Something went wrong</p>
              </Alert>
            ),
            {
              toastId: 'error'
            }
          )
        })
    }
  }

  const handleChangeEmail = (e) => setEmail(e.target.value)

  return (
    <main className="page">
      <div className={classes.container}>
        <div className={classes.breadcrumbsContainer}>
          <Breadcrumbs
            data={[{ title: 'For Businesses', href: '/business' }]}
          />
        </div>
        <div className={classes.firstScreen}>
          <div className={classes.info}>
            <h2 className={classes.title}>coming soon!</h2>

            <h3 className={classes.subTitle}>
              Send invitations to customers, or allow customers to schedule
              appointments.
            </h3>

            <p className={classes.bold}>
              SoPlan can be used by many local businesses such as a gym, dental
              clinic, hair salon, beauty salon, restaurant, garage etc.
            </p>

            <p>
              Allow customers to schedule their own appointments with your
              business by using your business phone number. No more picking up
              phone calls to create, reschedule or delete appointments.
            </p>

            <p>
              Or send your customers invitations and check their attendance
              status real-time. No more sending text messages or letters and not
              knowing whether they got the message.
            </p>

            <p>
              No more last minute cancellations because customers forget to note
              it in their agenda.
            </p>

            <p>With SoPlan, planning is made simple.</p>
          </div>

          <div className={classes.img} />
        </div>
        <div className={classes.formContainer}>
          <h3 className={classes.formTitle}>
            Be an early adopter and get to use our platform for free¹
          </h3>
          <form className={classes.form}>
            <Input
              value={email}
              className={classes.input}
              onChange={handleChangeEmail}
              placeholder="Enter your e-mail"
              name="email"
              type="text"
            />

            <SubmitButton className={classes.button} onClick={handleClick}>
              Subscribe
            </SubmitButton>

            <ReCAPTCHA
              ref={captchaRef}
              className={classes.reCaptcha}
              onChange={handleReCaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <p className={classes.note}>
              1. You receive one business plan registration for free with an
              unlimited amount of sending and receiving invitation credits. This
              promotion is only applicable for the first 1000 subscribers. You
              as an individual are only allowed to subscribe once.
            </p>
          </form>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </main>
  )
}

export default Business
