import icon1 from 'assets/logo/card-icon1.svg'
import icon2 from 'assets/logo/card-icon2.svg'
import icon3 from 'assets/logo/card-icon3.svg'
import icon4 from 'assets/logo/card-icon4.svg'

export default [
  {
    icon: icon1,
    title: 'Communication',
    text:
      'Communication between the app and our serves is encrypted using TLS 1.2 or higher and strong ciphers. We do encourage you to use only networks you trust because there is always a way for hackers to mislead you.'
  },
  {
    icon: icon2,
    title: 'Storage',
    text:
      'All your events are stored with encryption-at-rest in our datacenter. This means if someone gains access to our storage, they will be faced with AES-256 bit encryption.'
  },
  {
    icon: icon3,
    title: 'Your Account',
    text: `Your SoPlan account is protected by a verification code which is valid for 5 minutes and against bruteforce attempts.
    If someone starts the authentication process using your number, you will be notified immediately `
  },
  {
    icon: icon4,
    title: 'We don’t sell your data',
    text:
      'SoPlan will not sell your data to third parties. We believe that there are other ways to make profit. '
  }
]
