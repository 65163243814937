import React from 'react'
// prop-types
import T from 'prop-types'
// react-router-dom
import { Link } from 'react-router-dom'
// css
import classes from 'components/Breadcrumbs/Breadcrumbs.module.css'

const Breadcrumbs = ({ data }) => {
  return (
    <div className={classes.breadcrumbs}>
      {data?.map((item, i) => (
        <div key={i}>
          <Link to={item.href} className={classes.link}>
            <h2 className={classes.title}>{item.title}</h2>
          </Link>
          <span className={classes.separator}>/</span>
        </div>
      ))}
    </div>
  )
}

Breadcrumbs.propTypes = {
  data: T.arrayOf(T.object).isRequired
}

export default Breadcrumbs
