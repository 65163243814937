export const ourServices = {
  title: 'Our Services',
  list: [
    {
      bold: 'Privacy And Security.',
      text:
        'Every functionality we build requires privacy and security implemented during development. This is what we call Privacy and Security by Design principle.'
    },
    {
      bold: 'Meeting With Other People.',
      text:
        ' We provide ways for you to schedule events with other SoPlan users. '
    },
    {
      bold: 'Communicating With Businesses.',
      text:
        ' We may provide ways for you and busineses, to schedule events with each other, such as through appointment, reservation, delivery and shipping. You can manage these communications, and we will honor the choices you make.'
    },
    {
      bold: 'Enabling Global Access To Our Services.',
      text:
        ' To operate our global Service, we need to store and distribute content and information in data centers and systems around the world, including outside your country of residence. This infrastructure may be owned or operated by our service providers or affiliated companies.'
    },
    {
      bold: 'Ways To Improve Our Services.',
      text:
        ' We analyze how you use SoPlan, in order to improve all aspects of our Services.'
    }
  ]
}

export const otherSubList = {
  list: [
    {
      text:
        'Unless a mutually executed agreement between you and us states otherwise, our Terms make up the entire agreement between you and us regarding SoPlan and our Services, and supersede any prior agreements.'
    },
    {
      text:
        'We reserve the right to designate in the future that certain of our Services are governed by separate terms (where, as applicable, you may separately consent).'
    },
    {
      text:
        'Our Services are not intended for distribution to or use in any country where such distribution or use would violate local law or would subject us to any regulations in another country. We reserve the right to limit our Services in any country.'
    },
    {
      text:
        'You will comply with all applicable United States and non-United States export control and trade sanctions laws ("Export Laws"). You will not, directly or indirectly, export, re-export, provide, or otherwise transfer our Services: (a) to any individual, entity, or country prohibited by Export Laws; (b) to anyone on United States or non-United States government restricted parties lists; or (c) for any purpose prohibited by Export Laws, including nuclear, chemical, or biological weapons, or missile technology applications without the required government authorizations. You will not use or download our Services if you are located in a restricted country, if you are currently listed on any United States or non-United States restricted parties list, or for any purpose prohibited by Export Laws, and you will not disguise your location through IP proxying or other methods.'
    },
    {
      text:
        'Any amendment to or waiver of our Terms requires our express consent. You have the right to terminate your relationship with SoPlan at any time by deleting your account.'
    },
    {
      text:
        'We may amend or update these Terms. Unless otherwise required by law, we will provide you at least 30 days\' notice of amendments to our Terms, which will give you the opportunity to review the revised Terms before continuing to use our Services. We will also update the "Last Modified" date at the top of our Terms. Changes to these Terms shall become effective no sooner than 30 days after we provide notice of planned changes. Please note that we may not be able to provide such notice for changes to these Terms that are required to address technical evolutions of our Services or for changes made for legal reasons, both of which will become effective immediately. Your continued use of our Services following the notice period of planned changes confirms your acceptance of our Terms, as amended. We hope you will continue using SoPlan, but if you do not agree to our Terms, as amended, you must stop using our Services by deleting your account.'
    },
    {
      text:
        'All of our rights and obligations under our Terms are freely assignable by us to any of our affiliates or in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner. In the event of such an assignment, these Terms will continue to govern your relationship with such third party. We hope you will continue using SoPlan, but if you do not agree to such an assignment, you must stop using our Services by deleting your account.'
    },
    {
      text:
        'You will not transfer any of your rights or obligations under our Terms to anyone else without our prior written consent.'
    },
    {
      text: 'Nothing in our Terms will prevent us from complying with the law.'
    },
    {
      text:
        'Except as contemplated herein, our Terms do not give any third-party beneficiary rights.'
    },
    {
      text:
        'If we fail to enforce any of our Terms, it will not be considered a waiver.'
    },
    {
      text:
        'If any provision of these Terms is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from our Terms and shall not affect the validity and enforceability of the remaining provisions of our Terms, and the remaining portion of our Terms will remain in full force and effect.'
    },
    {
      text:
        'We reserve all rights not expressly granted by us to you. In certain jurisdictions, you may have legal rights as a consumer, and our Terms are not intended to limit such consumer legal rights that may not be waived by contract. Also, in certain jurisdictions, you may have legal rights as a data subject, and our Terms are not intended to limit such rights that may not be waived by contract.'
    },
    {
      text:
        'We always appreciate your feedback or other suggestions about SoPlan and our Services, but you understand that you have no obligation to provide feedback or suggestions and that we may use your feedback or suggestions without any obligation to compensate you for them.'
    }
  ]
}
