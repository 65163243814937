import React from 'react'
// components
import Breadcrumbs from 'components/Breadcrumbs'
// css
import classes from 'pages/About/About.module.css'

const About = () => (
  <main className="page">
    <div className={classes.container}>
      <Breadcrumbs data={[{ title: 'About SoPlan', href: '/about' }]} />
      <h1 className={classes.title}>Our Mission</h1>
      <p className={classes.paragraph}>
        SoPlan started as an alternative to e-mail based event scheduling. We
        believe that if it’s easier to schedule events with friends and
        families, people will start meeting more often and strengthen their
        bonds. With SoPlan you can send and receive invitations to events based
        on mobile phone numbers.
      </p>

      <p className={classes.paragraph}>
        All invited individuals can check the attendance status of the rest of
        the group. As the organiser of an event you can configure reminders for
        all invited members. All your information is secured with encryption at
        rest using 256-bit Advanced Encryption Standard (AES-256) which helps
        secure your data from unauthorized access to the underlying storage. All
        communication between your device and our servers is secured using
        Transport Layer Security 1.2 (TLS 1.2) using strong ciphers only. And
        lastly, we adhere secure-by-design principles.
      </p>

      <p className={classes.paragraph}>Our mission: Planning made simple.</p>
    </div>
  </main>
)

export default About
