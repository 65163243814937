import React from 'react'
// prop-types
import T from 'prop-types'
// css
import classes from 'components/SubList/SubList.module.css'

const SubList = ({ data: { title, list } }) => {
  return (
    <div className={classes.container}>
      {title && <h4 className={classes.title}>{title}</h4>}

      <ul className={classes.list}>
        {list.map((item, i) => (
          <li className={classes.item} key={i}>
            {item.bold && <span className={classes.bold}>{item.bold} </span>}
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  )
}

SubList.propTypes = {
  data: T.shape({
    title: T.string,
    list: T.arrayOf(T.object)
  })
}

export default SubList
